

















































import { mapMutations, mapGetters, mapState } from 'vuex';
import RepositoryFactory from '@/services/RepositoryFactory';
import ScannerMixin from '@/mixins/ScannerMixin';
import IBoxesRepository from '@/services/Repositories/Boxes/types';
import { IOrder } from '@/services/Repositories/OrdersRepository/types';
import TheFabButton from '@/components/ui/TheFabButton.vue';
import LastEdited from '@/components/ui/LastEdited.vue';
import BasePopup from '@/components/ui/BasePopup.vue';

const Factory = new RepositoryFactory();

export default ScannerMixin.extend({
  name: 'ReboxCreate',
  data() {
    return {
      damagedBoxPopupIsOpen: false,
      boxUnavailablePopupIsOpen: false,
      boxStatus: '',
    };
  },
  components: {
    TheFabButton,
    LastEdited,
    BasePopup,
  },
  computed: {
    ...mapGetters('orders', ['getOrder']),
    ...mapState('lastEdited', ['editedOrders']),
    orderId(): string {
      const {
        params: { orderId },
      } = this.$route;
      return orderId;
    },
    order(): IOrder {
      return this.getOrder(this.orderId);
    },
    boxesRepository(): IBoxesRepository {
      return Factory.get('boxes') as IBoxesRepository;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_PAGE']),
    async reboxSearch(reboxId: string) {
      const { merchant } = this.order;
      await this.boxesRepository.registerBox(reboxId, { merchant });
      this.$router.push({
        name: 'createRebox',
        params: {
          orderId: this.orderId,
          reboxId,
        },
      });
    },
  },
  async created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      title: 'header.createRebox',
      icons: { icon: icons.back, action: actions.goBack },
      page: { name: 'orderDetails', params: { orderId: this.orderId } },
    });
    this.SET_PAGE('');
  },
});
