
















import Vue from 'vue';

export default Vue.extend({
  name: 'LastEdited',
  props: {
    items: {
      type: Array as () => Array<string>,
      default: () => [],
    },
    itemText: {
      type: String,
    },
  },
  computed: {
    showFew(): Array<string> {
      return this.items.slice(0, 4);
    },
  },
});
